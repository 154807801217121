var quizQuestions = [
  {
      question: "Was für ein Typ bist Du?",
      answers: [
          {
              type: "1",
              content: "extrovertiert"
          },
          {
              type: "2",
              content: "introvertiert"
          },
          {
              type: "3",
              content: "beides"
          }
      ]
  },
  {
    question: "Was hat Dich in Deinem Leben am meisten geprägt?",
    answers: [
        {
            type: "1",
            content: "Familie"
        },
        {
              type: "2",
              content: "Freunde"
        },
        {
              type: "3",
              content: "Job"
        }
    ]
},
{
    question: "Hast Du schon ein Buch-Script geschrieben?",
    answers: [
        {
            type: "1",
            content: "Ja"
        },
        {
            type: "2",
            content: "Nein"
        }
    ]
},
{
    question: "Bist Du...",
    answers: [
        {
            type: "1",
            content: "selbstständig"
        },
        {
            type: "2",
            content: "angestellt"
        },
        {
            type: "3",
            content: "Coach:in"
        },
        {
            type: "4",
            content: "Trainer:in"
        },
        {
            type: "5",
            content: "Speaker:in"
        }
    ]
},
{
    question: "Was für ein Buch möchtest Du schreiben?",
    answers: [
        {
            type: "1",
            content: "Sachbuch"
        },
        {
            type: "2",
            content: "Ratgeber"
        },
        {
            type: "3",
            content: "Expertenbuch"
        },
        {
            type: "4",
            content: "Fachbuch"
        },
        {
            type: "5",
            content: "Thriller"
        },
        {
            type: "6",
            content: "Roman"
        },
        {
            type: "7",
            content: "Science-Fiction"
        },
        {
            type: "8",
            content: "Sonstiges"
        },
        {
            type: "9",
            content: "Weiß es noch nicht"
        }
    ]
},
{
    question: "Warum würdest Du gerne ein Buch schreiben?",
    answers: [
        {
            type: "1",
            content: "Ich habe eine Botschaft, die ich weitergeben will"
        },
        {
            type: "2",
            content: "Ich will meine Erfahrungen teilen"
        },
        {
            type: "3",
            content: "Ich möchte meine Expertise präsentieren"
        }
    ]
}
];

export default quizQuestions;
