import React, { Component } from 'react';
import axios from 'axios';
import quizQuestions from './api/quizQuestions';
import Quiz from './components/Quiz';
// import Result from './components/Result';
import Form from './components/Form';
// import logo from './svg/logo.png'; <img src={logo} className="App-logo" alt="logo" />
import Image from './svg/header-hs.png';
import './App.css';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      counter: 0,
      questionId: 1,
      question: '',
      answerOptions: [],
      answer: '',
      answersCount: {},
      answerLast: '',
      answerCode: '',
      result: '',
      firstName: '',
      lastName: '',
      email: '',
      emailSent: false,
      error: null,
      tag: '',
      eventSourceUrl: 'https://autor.hermannscherer.com/',
      eventId: 'autor-lead-' + Math.floor((Math.random() * 10000) + 1),
      fbp: this.getCookieByName('_fbp'),
      fbc: this.getCookieByName('_fbc')
    };

    this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
    this.setPrevQuestion = this.setPrevQuestion.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    const shuffledAnswerOptions = quizQuestions.map(question =>
      this.shuffleArray(question.answers)
    );
    this.setState({
      question: quizQuestions[0].question,
      answerOptions: shuffledAnswerOptions[0]
    });
    const advancedMatching = { 
    };
    const options = {
      autoConfig: false
    };
    ReactPixel.init('548158031999193', advancedMatching, options);
    ReactPixel.pageView();
    const tagManagerArgs = {
      gtmId: 'GTM-PP5MCP3'
    };
    TagManager.initialize(tagManagerArgs);
    window.dataLayer.push({
      event: "pageView",
      pagePath: 'start' + window.location.search,
      pageTitle: 'Start'
    });
  }

  shuffleArray(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  handleAnswerSelected(event) {
    this.setUserAnswer(event.currentTarget.value);

    if (this.state.questionId < quizQuestions.length) {
      setTimeout(() => this.setNextQuestion(), 300);
    } else {
      setTimeout(() => this.setResults(this.getResults()), 300);
    }
  }

  setUserAnswer(answer) {
    this.setState({
      answerCode: this.state.answerCode + answer + ' ',
      answerLast: answer,
      answer: answer
    });
  }

  setNextQuestion() {
    const counter = this.state.counter + 1;
    const questionId = this.state.questionId + 1;

    this.setState({
      counter: counter,
      questionId: questionId,
      question: quizQuestions[counter].question,
      answerOptions: quizQuestions[counter].answers,
      answer: ''
    });
  }

  setPrevQuestion() {
    const counter = this.state.counter - 1;
    const questionId = this.state.questionId - 1;

    this.setState({
      counter: counter,
      questionId: questionId,
      question: quizQuestions[counter].question,
      answerOptions: quizQuestions[counter].answers,
      answer: ''
    });
  }

  handleFormSubmit(event) {
    ReactPixel.track('Lead', {}, {eventID: this.state.eventId });
    window.dataLayer.push({
      event: "pageView",
      pagePath: 'danke' + window.location.search,
      pageTitle: 'Danke'
    });
    axios({
      method: 'post',
      url: 'https://autor.hermannscherer.com/api/mail/index.php',
      headers: { 'content-type': 'application/json' },
      data: this.state
    })
    .then(result => {
      this.setState({
        emailSent: result.data.sent
        });
      setTimeout(function(){ window.location = "https://go.hermannscherer.com/danke-autorentest1" + window.location.search; }, 7500);
    })
    .catch(error => {
      this.setState({ error: error.message });
    });
  }
  
  getCookieByName(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
   }

  getResults() {
    const question5 = this.state.answerCode.slice(8, 9)
    const question6 = this.state.answerCode.slice(10, 11)

    switch (question5) {
      case "1":
      case "2":  
      case "3":
      case "4":
      case "8": 
      case "9":
        if (question6 === "1" || question6 === "2") {
          this.setState({ answerLast: 'Expertenbuch Botschaft'})
        } else {
          this.setState({ answerLast: 'Expertenbuch Wissen'})
        }
        break;
      case "5":
      case "6": 
      case "7": 
        this.setState({ answerLast: 'Thriller/Roman'})
        break;
      default:
        this.setState({ answerLast: 'Thriller/Roman'})
   }
    return this.state.answerLast;
  }

  setResults(result) {
    this.setState({ result: result })
  }

  renderQuiz() {
    return (
      <Quiz
        answer={this.state.answer}
        answerOptions={this.state.answerOptions}
        questionId={this.state.questionId}
        question={this.state.question}
        questionTotal={quizQuestions.length}
        onAnswerSelected={this.handleAnswerSelected}
        onPrevQuestion={this.setPrevQuestion}
      />
    );
  }

  renderResult() {
    // return <Result quizResult={this.state.result} />;
    return <Form quizResult={this.state.result} email={this.state.email} emailSent={this.state.emailSent} error={this.state.error} firstName={this.state.firstName} lastName={this.state.lastName} handleChange={this.handleChange} onFormSubmit={this.handleFormSubmit} />;
  }

  render() {
    return (
      <div className="App" style={{backgroundImage: "url(" + Image + ")", backgroundSize: "cover"}}>
        <div className="App-header">
          <p className="header">&gt;&gt; MACHE DEN TEST &lt;&lt;</p>
          <h1 className="header">HAST DU DAS ZEUG ZUM AUTOR / ZUR AUTORIN?</h1>
        </div>
        <div className="App-Container">
        {this.state.result ? this.renderResult() : this.renderQuiz()}
        </div>
        <div className="App-footer">
          <p>
            <a href="https://www.hermannscherer.com/impressum/" className="footer" target="_blank" rel="noopener noreferrer">Impressum</a>
            <span className="footer"> · </span>
            <a href="https://www.hermannscherer.com/datenschutz/" className="footer" target="_blank" rel="noopener noreferrer">Datenschutz</a>
          </p>
          <p>© Hermann Scherer</p>
        </div>
      </div>
    );
  }
}

export default App;
