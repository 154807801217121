import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

function DataForm(props) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      props.onFormSubmit();
    }

    setValidated(true);
  };
  return  (
    <Container>
      <Row className="justify-content-md-center">
        <Col className="mt-5" md="auto">
          <p className="resultText"><strong>Fast geschafft!</strong></p>
          <p className="resultText">Deine ganz persönlichen Empfehlungen senden wir Dir in wenigen Minuten per E-Mail zu.</p>
          <Form className="resultForm" noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="resultInput" controlId="formBasicName">
              <Form.Label>Vorname</Form.Label>
              <Form.Control type="text" name="firstName" value={props.firstName} onChange={props.handleChange} placeholder="Vorname" required />
              <Form.Control.Feedback type="invalid">Bitte Vorname eingeben.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="resultInput" controlId="formBasicName">
              <Form.Label>Nachname</Form.Label>
              <Form.Control type="text" name="lastName" value={props.lastName} onChange={props.handleChange} placeholder="Nachname" required />
              <Form.Control.Feedback type="invalid">Bitte Nachname eingeben.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="resultInput" controlId="formBasicEmail">
              <Form.Label>E-Mail-Adresse</Form.Label>
              <Form.Control type="email" name="email" value={props.email} onChange={props.handleChange} placeholder="E-Mail" required />
              <Form.Control.Feedback type="invalid">Bitte E-Mail-Adresse eingeben.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="resultInput" controlId="formBasicEmail">
              <Form.Check>
              <Form.Check.Input type="checkbox" className="resultDatenschutz" required/>
              <Form.Check.Label>Ich habe die <a href='https://hermannscherer.com/datenschutz' target='_blank' rel='noopener noreferrer'>Datenschutzerklärung</a> zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden.</Form.Check.Label>
              <Form.Control.Feedback type="invalid">Bitte Datenschutzbestimmungen zustimmen.</Form.Control.Feedback>
              </Form.Check>
            </Form.Group>
            <Form.Group className="resultInput" controlId="formBasicEmail">
            <div className="text-center">
            <Button variant="primary" type="submit">
            Senden
            </Button>
            </div>
            <div className="text-center">
            {props.emailSent &&
            <div>
            <br/>
            <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
            </Spinner>
            <p>Du erhältst nun eine E-Mail.</p>
            </div>
            }
            </div>
            </Form.Group>
            <Form.Group className="resultInput" controlId="formBasicEmail">
            <div className="text-center">
              <p>Dein Ergebnis zeigt Dir</p>
              <ul className="text-left">
                <li>Wie du das Beste aus Dir rausholst</li>
                <li>Wie wir Dir bei der Umsetzung helfen können</li>
                <li>Wo Deine Chancen liegen</li>
              </ul>
            </div>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

DataForm.propTypes = {
    email: PropTypes.string.isRequired,
    emailSent: PropTypes.bool,
    error: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    quizResult: PropTypes.string.isRequired
};

export default DataForm;